<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="autonumbersetlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows :scrollHeight="sHeight" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="50" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-4">
                        <div class="text-left">
                            <InputText placeholder="名称" id='searchUserNameInput' v-model="searchName" type="text" />
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="新建" icon="pi pi-plus" @click="createdetail()"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="name" header="名称" style="width: 20%" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.name" @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column field="categories" header="类别" style="width: 20%"></Column>
            <Column field="currentvalue" header="当前值" style="width: 20%">
            </Column>
            <Column field="ruler" header="规则" style="width: 40%"></Column>
        </DataTable>
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="displayMaximizable" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="field">
                <label for="name">名称</label>
                <InputText id='name' v-model="currentRow.name" type="text" />
            </div>
            <div class="field">
                <label for="categories">类别</label>
                <InputText id='categories' v-model="currentRow.categories" type="text" />
            </div>
            <div class="field">
                <label for="currentvalue">当前值</label>
                <InputNumber id="currentvalue" v-model="currentRow.currentvalue" mode="decimal" :useGrouping="false" />
            </div>
            <div class="field">
                <label for="ruler">规则</label>
                <InputText id='ruler' v-model="currentRow.ruler" type="text" />
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../js/Common.js';
    import MessageTip from '../../components/Message';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm
            };
        },
        data() {
            return {
                selectedData: ref(),
                searchName: ref(),
                currentRow: null,
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                recordsubject: '',
                totalRecords: 0,
                first: 0,
                autonumbersetlist: [],
                sHeight: '650px',
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 300) + "px";
            this.loadData(1);
        },
        methods: {
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'name',
                        value: this.searchName,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{autonumbersetlist(where:" + JSON.stringify(listwhere) +
                    "){id name ruler currentvalue categories}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.autonumbersetlist = jsonData.data.autonumbersetlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            opendetail(v_row) {
                this.currentRow = v_row;
                this.recordsubject = v_row.name;
                this.displayMaximizable = true;
            },
            createdetail() {
                var row = {
                    id: '-1',
                    name: '',
                    ruler: '',
                    currentvalue: 0,
                    categories: '',
                };
                this.currentRow = row;
                this.recordsubject = '新建';
                this.displayMaximizable = true;
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['id']) {
                    this.loading = true;
                    var id = this.selectedData['id'];
                    var title = this.selectedData['name'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{autonumberset(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.autonumbersetlist.length; i++) {
                                        if (this.autonumbersetlist[i]['id'] == id) {
                                            this.autonumbersetlist.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                if (this.currentRow) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem(
                            "appkey"),
                        'mutation{autonumberset(o:' + JSON.stringify(this.currentRow) +
                        '){id}}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.currentRow = null;
                            this.loadData(1);
                            this.closeMaximizable();
                            MessageTip.successmsg('保存成功');
                        }
                    });
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
    }
</script>